@import './_colors'

.App-root-1
  position: relative

.MuiDrawer-root
  position: absolute
  z-index: 1205

.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft
  
  & > div
    min-height: 56px

.MuiToolbar-gutters.MuiToolbar-regular
  min-height: 56px

// .App-drawerClose-8
//   z-index: 100

//   .App-toolbar-9
//     min-height: 56px

h1
  margin-top: 10px
  margin-bottom: 10px

main
  position: relative
  width: 100%
  height: calc(100vh - 51px)
  flex-grow: 1

  iframe
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: calc(100vh - 56px)
    margin-top: 56px
    border: 0

.container

  .user
    display: flex
    flex-direction: column
    width: 100%

    &__photo
      text-align: center
      margin-bottom: 20px

    &__photo-container
      display: flex
      margin: 0 auto
      width: 200px
      height: 200px
      overflow: hidden
      border: 1px solid $grey-color

      img
        width: 100%
        height: 100%
        padding: 5px
        object-fit: cover

    &__text

      p

        &:last-child
          margin-bottom: 0

      span
        font-weight: bold

    h2
      margin-top: 10px
      margin-bottom: 0

    p
      margin-top: 0
      margin-bottom: 10px

.phrase
  display: flex
  flex-direction: column
  margin-bottom: 20px

  &__text
    display: flex
    margin-bottom: 10px

    svg
      margin-top: -5px

    p
      font-size: 18px
      font-weight: 500
      font-style: italic

  &__author
    margin-left: 5px

    p
      color: $dark-grey-color
      font-style: italic

.btn-container
  margin-top: 20px

  .btn
    margin-right: 10px

    &:last-child
      margin-right: 0

.dialog

  .MuiDialogTitle-root
    border-bottom: 1px solid $grey-color

  .MuiDialogContent-root
    padding-bottom: 20px

  .MuiFormControl-root
    width: 100%
    margin-bottom: 10px

  .btn-container
    width: 100%

.MuiButton-root.btn
  padding: 10px 18px
  background-color: $black-color
  border-radius: 0
  color: $white-color
  font-size: 17px
  font-weight: 400

  &_success
    background-color: $success-color

  &_danger
    background-color: $danger-color

  &_small
    padding: 5px 8px
    font-size: 14px

  &:hover,
  &:focus,
  &:active
    background-color: lighten($black-color, 20%)

.calendar

  &__title
    display: flex
    align-items: center
    margin-top: 10px
    margin-bottom: 10px

    button
      margin-right: 10px

    h1
      margin: 0

  &__content
    display: block

    h2
      margin-top: 0

    .date-info

      &__title
        margin-top: 20px
        margin-bottom: 20px

      .events
        display: flex
        flex-wrap: wrap

        h3
          width: 100%
          margin-top: 0

      .event
        width: 276px
        margin-right: 15px
        margin-bottom: 15px
        padding: 10px
        border: 1px solid $grey-color
        text-align: center

        &__photo
          width: 150px
          height: 150px
          margin: 0 auto
          padding: 5px
          overflow: hidden
          border-radius: 50%
          border: 1px solid $grey-color

          img
            width: 100%
            height: 100%
            object-fit: cover
            border-radius: 50%

        h5
          margin-top: 0
          margin-bottom: 10px
          font-size: 20px

        p
          margin: 0

          span
            font-weight: bold

.block
  padding: 10px
  background: $white-color
  border: 1px solid $grey-color

@media (min-width: 600px)

  .MuiDrawer-root
    position: relative

@media (min-width: 500px)

  .container

    .user
      align-items: flex-start
      flex-direction: row

      &__photo
        margin-right: 20px

      &__text
        flex-grow: 1

@media (min-width: 680px)

  .calendar

    &__content
      display: flex
      align-items: flex-start

      .date-info

        &__title
          margin-top: 0

    .DayPicker
      margin-right: 20px

    h1
      margin-top: 0

.nybg
  @media (min-width: 680px)
    padding-top: 200px
  @media (max-width: 680px)
    padding-top: 20px

.nytext
  @media (min-width: 680px)
    font-size: 24px
  @media (max-width: 680px)
    font-size: 18px